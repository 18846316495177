import { useState, useEffect, useCallback } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {useEndpoint, useToken} from "../../../hooks/api";

function useAgents() {
    const { getAccessTokenSilently, user } = useToken();
    const [agents, setAgents] = useState({});
    const [namespaces, setNamespaces] = useState([]);
    const [namespaceList, setNamespaceList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [agentCount, setAgentCount] = useState(0);
    const { fetchAuth: listAgents } = useEndpoint("gpt/list-agents");
    const { fetchAuth: listNamespaces } = useEndpoint("gpt/list-namespaces");

    async function fetchAgentsAsync() {
        try {
            const agentResponse = await listAgents();
            if (agentResponse) {
                agentResponse.sort((a, b) => {
                    if (a.namespace < b.namespace) return -1;
                    if (a.namespace > b.namespace) return 1;
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });
                let agentsMap = {};
                for (let i = 0; i < agentResponse.length; i++) {
                    if (!agentsMap[agentResponse[i].namespace]) agentsMap[agentResponse[i].namespace] = [];
                    agentsMap[agentResponse[i].namespace].push(agentResponse[i]);
                }
                setAgents(agentsMap);
                setAgentCount(agentResponse.length);
                setNamespaceList(Object.keys(agentsMap));
            }
        } catch (e) {
            setError(e);
        }
    }

    const fetchAgents = useCallback(async () => {
        await fetchAgentsAsync();
    }, [getAccessTokenSilently, artApiFetchAuthAsync]);

    async function fetchNamespacesAsync() {
        try {
            const namespaceResponse = await listNamespaces();
            if (namespaceResponse) {
                setNamespaces(namespaceResponse);
            }
        } catch (e) {
            setError(e);
        }
    }

    const fetchNamespaces = useCallback(async () => {
        await fetchNamespacesAsync();
    }, [getAccessTokenSilently, artApiFetchAuthAsync]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            console.log("Fetching agents...");
            await fetchAgentsAsync();
            console.log("Fetching namespaces...");
            await fetchNamespacesAsync();
            setIsLoading(false);
        }

        fetchData();
    }, [fetchAgents, fetchNamespaces]);

    return { agents, agentCount, namespaces, namespaceList, isLoading, error, fetchAgents, fetchNamespaces };
}

export default useAgents;
