import {useEffect, useState} from "react";
import {useEndpoint} from "./api";
import {useAuth0} from "@auth0/auth0-react";

export const useEntitlement = (entitlement) => {
    const { isEntitled: checkCachedEntitlement, isLoadingEntitlements, checkEntitlement: checkEntitlementAsync } = useEntitlements();
    const [ isEntitled, setIsEntitled ] = useState(undefined);

    useEffect(() => {
        setIsEntitled(checkCachedEntitlement(entitlement));
    }, [isLoadingEntitlements])

    const checkEntitlement = async () => {
        return checkEntitlementAsync(entitlement);
    }

    return {isEntitled, isLoadingEntitlements, checkEntitlement}
}

export const useEntitlements = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [ entitlements, setEntitlements ] = useState([]);
    const [ isLoadingEntitlements, setIsLoadingEntitlements ] = useState(true);
    const { fetchAuth } = useEndpoint("entitlements-list");

    async function getEntitlements() {
        if(window.entCache) {
            const cachedResponse = await window.entCache;
            setEntitlements(cachedResponse);
            return cachedResponse;
        }

        setIsLoadingEntitlements(true);
        const promise = fetchAuth();
        window.entCache = promise;
        const response = await promise;
        setEntitlements(response);
        setIsLoadingEntitlements(false);
    }

    useEffect(() => {
        getEntitlements();
    }, [user]);

    const isEntitled = (entitlement) => {
        const entitled = entitlements && entitlements.includes(entitlement);
        return entitled;
    }

    async function checkEntitlement(entitlement) {
        await getEntitlements();
        return isEntitled(entitlement);
    }

    return {isEntitled, isLoadingEntitlements, entitlements, checkEntitlement};
}