import React, {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {MdApps, MdContentPaste, MdDelete, MdFileCopy, MdLink, MdNewLabel, MdOutlineVerifiedUser} from "react-icons/md";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";
import {
    artApiFetchAsync,
    artApiFetchAuthAsync,
    artApiPostAuthAsync,
    useAuthenticatedArtApi
} from "../../../hooks/artapi";
import {Button, Card, Dropdown, DropdownButton, Form, ListGroup, Tab, Tabs} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import FloatingForm, {FloatingFormHeader} from "../../components/gpt/floatingform";
import MessageList from "../../components/gpt/contentlist";
import {CollapsibleCard, CollapsibleCardContent} from "../../components/CollapsableCard";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import {MdAdd, MdAddBox, MdCopyAll, MdShare} from "react-icons/all";
import ModalDialog from "../../components/dialog/modaldialog";
import {ConfigProvider, theme, TreeSelect} from "antd";
import AgentChatWindow from "../../components/chat/agentChatWindow";
import AgentChooser from "../../components/chat/agentchooser";
import * as PropTypes from "prop-types";
import CodePreview from "../../components/codepreview/codepreview";
import LiteGraphComponent from "../../components/litegraph/litegraphcomponent";
import useAgents from "./agenthook";
import AgentPromptForm from "../../components/agent/agentpromptform";
import {useParams} from "react-router-dom";
import {useEndpoint} from "../../../hooks/api";


const AgentDesign = () => {
    let { id, tab } = useParams();
    const { getAccessTokenSilently, user } = useAuth0();
    const { response: entitlements, error: entitlementError } = useAuthenticatedArtApi("entitlements-list");
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const defaultImage = '/img/headers/agent.png';
    var headerImage = defaultImage;
    const [savedState, setCookie, removeCookie] = useCookies(['mjjobsubmitter']);
    const [ agent, setAgent ] = React.useState(null);
    const [ messages, setMessages ] = React.useState([]);

    const [messageRole, setRole] = React.useState('user');
    const [messageContent, setContent] = React.useState('');
    const [promptValue, setPromptValue] = React.useState('');
    const [responseValue, setResponseValue] = React.useState('');
    const [createNamespace, setCreateNamespace] = React.useState(false);
    const [createAgent, setCreateAgent] = React.useState(false);
    const [newNamespaceName, setNewNamespaceName] = React.useState('');
    const [ apiKeys, setApiKeys ] = React.useState([]);
    const [ createApiKey, setCreateApiKey ] = React.useState(false);
    const [ apiKeyDescription, setApiKeyDescription ] = React.useState('');
    const [ persistConversation, setPersistConversation ] = React.useState(false);
    const [ conversationHistory, setConversationHistory ] = React.useState([]);

    // Agent Name Fields
    const [newAgentName, setNewAgentName] = React.useState('');
    const [newAgentIsPrivate, setNewAgentIsPrivate] = React.useState(false);
    const [newAgentDescription, setNewAgentDescription] = React.useState('');
    const [newAgentNamespace, setNewAgentNamespace] = React.useState('');
    const [isGenerating, setIsGenerating] = React.useState(false);
    const [models, setModels] = React.useState([]);
    const [modelIterations, setModelIterations] = React.useState(1);
    const [services, setServices] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState(tab ?? "content");
    const [namespaceList, setNamespaceList] = React.useState([]);
    const [postData, setPostData] = React.useState('');
    const [rawPostData, setRawPostData] = React.useState({});

    // Add Modal for Add Agent
    const [showAddAgent, setShowAddAgent] = useState(false);

    // Add Modal for Add Content
    const [showAddContent, setShowAddContent] = useState(false);

    // Get Window Width
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const { agents, namespaces, isLoading, error, fetchAgents, fetchNamespaces } = useAgents(getAccessTokenSilently, artApiFetchAuthAsync);
    const { fetch: listModels } = useEndpoint("gpt/list-models");
    const { fetch: listServices } = useEndpoint("gpt/list-services");
    const { fetchAuth: listContent } = useEndpoint("gpt/list-content");


    async function fetchServices() {
        const services = await listServices();
        if(services) {
            setServices(services);
        }
    }

    async function fetchModels() {
        if(agent) {
            const models = await listModels({"service": agent?.service});
            if (models) {
                setModels(models);
                setModelIterations(modelIterations + 1);
            }
        }
    }

    const tabHeight = windowHeight - 400;

    useEffect(() => {
        if(savedState.agent) setAgent(savedState.agent);
    }, ['savedState']);

    useEffect(() => {
        fetchServices();
    }, [userInfo]);

    useEffect(() => {
        if(!agent) {
            if(agents && agents.length > 0) {
                setAgent(agents[0]);
            }
        }
        setNamespaceList(Object.keys(agents));
    }, [agents]);

    useEffect(() => {
        fetchModels();
    }, [agent]);

    useEffect(() => {
        console.log("Models updated", models);
    }, [modelIterations]);


    async function fetchApiKeys() {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const apiKeyResponse = await artApiFetchAuthAsync(token, "gpt/keys/list", "id=" + agent.id);
        if(apiKeyResponse ) {
            setApiKeys(apiKeyResponse);
        }
    }

    async function fetchMessages() {
        const messageResponse = await listContent({
            "agent": agent.name,
            "namespace": agent.namespace
        });
        if(messageResponse) {
            // sort the messageREsponse by sort_order
            messageResponse.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);

            setMessages(messageResponse);
        }
    }
    useEffect(() => {
        if(!agent) return;

        fetchMessages();
        fetchApiKeys();
    }, [agent]);

    function applyAgent(agent) {
        setAgent(agent);
        setCookie('agent', agent);
    }

    function onAgentSelected(agent) {
        applyAgent(agent);
    }

    async function addContentSet(contentSet) {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        // url component encode the prompt value
        const content = encodeURIComponent(messageContent);
        for(let i = 0; i < contentSet.length; i++) {
            const messageRole = contentSet[i].role;
            const messageContent = contentSet[i].content;
            await artApiFetchAuthAsync(token, "gpt/add-content",
                "agent=" + agent.name,
                "namespace=" + agent.namespace,
                "role=" + messageRole,
                "content=" + messageContent);
        }
        await fetchMessages();
    }

    function addContent() {
        // The prompts you describe should all provide visual descriptions of everything seen as you would describe it to an artist.

        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            // url component encode the prompt value
            const content = encodeURIComponent(messageContent);
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/add-content",
                "agent=" + agent.name,
                "namespace=" + agent.namespace,
                "role=" + messageRole,
                "content=" + content);
            if(messageResponse) {
                await fetchMessages();
            }
        }

        fetchResponse();
    }

    function submitPrompt(promptValue) {
        console.log('Prompt:', promptValue);
        setIsGenerating(true);
        async function fetchResponse() {
            if(!agent) {
                setIsGenerating(false);
                return;
            }
            if(!promptValue) {
                setIsGenerating(false);
                return;
            }

            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            // url component encode the prompt value
            const prompt = encodeURIComponent(promptValue);
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/agent", "agent=" + agent.name, "namespace=" + agent.namespace, "prompt=" + prompt,
                "messages=" + (persistConversation ? encodeURIComponent(JSON.stringify(conversationHistory)) : ""));
            if(messageResponse) {
                setResponseValue(messageResponse.content);
                setConversationHistory([...conversationHistory, promptValue]);
            }
            setIsGenerating(false);
        }

        fetchResponse();
    }

    function handleCreateNamespace() {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/create-namespace", "namespace=" + newNamespaceName);
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(messageResponse.error);
            } else {
                toast.success("Namespace created.\n" + newNamespaceName);
                setNewNamespaceName('');
                setCreateNamespace(false);
            }
            await fetchNamespaces();
        }

        fetchResponse();
    }

    function handleCreateAgent() {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/create-agent",
                "agent=" + newAgentName,
                "namespace=" + newAgentNamespace,
                "isPrivate=" + newAgentIsPrivate,
                "description=" + newAgentDescription);
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to create agent ${newAgentName}.\n${messageResponse.error}`);
            } else {
                toast.success("Agent created.\n" + newAgentName);
                setNewAgentName('');
                setCreateAgent(false);
            }
            await fetchNamespaces();
            await fetchAgents();
        }

        fetchResponse();
    }

    function handleCreateApiKey(event) {
        event.preventDefault();
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/keys/keygen",
                "id=" + agent.id,
                "description=" + apiKeyDescription);
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to create API key for agent ${agent.name}.\n${messageResponse.error}`);
            } else {
                toast.success("API key created.");
                setApiKeyDescription('');
                setCreateApiKey(false);
            }
            await fetchApiKeys();
        }

        fetchResponse();
    }

    const onDragEnd = (result) => {
        // Check if the item was dropped outside the list
        if (!result.destination) {
            return;
        }

        const newMessages = [...messages];
        const [reorderedItem] = newMessages.splice(result.source.index, 1);
        newMessages.splice(result.destination.index, 0, reorderedItem);

        // Update the sort_order property of the messages based on their new order
        for (let i = 0; i < newMessages.length; i++) {
            newMessages[i].sort_order = i + 1;
        }

        updateMessages(newMessages);

        setMessages(newMessages);
    };

    async function updateMessages(messages) {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const messageResponse = await artApiPostAuthAsync(token, "gpt/update-content", JSON.stringify(messages), "id=" + agent.id);
        if(messageResponse) {
            await fetchMessages();
        }
    }

    const handleDeleteMessage = (id) => {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/delete-content", "id=" + id);
            if(messageResponse) {
                await fetchMessages();
            }
        }

        setMessages((prevMessages) => {
            const newMessages = prevMessages.filter((message) => message.id !== id);
            fetchResponse();
            return newMessages;
        });
    };

    function copyUrl(apiKey) {
        navigator.clipboard.writeText("https://api.aiart.doubtech.com/gpt/agent?key=" + apiKey + "&prompt=");
        toast.success("URL copied to clipboard");
    }

    function deleteKey(apiKey) {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/keys/delete", "key=" + apiKey);
            if(messageResponse) {
                await fetchApiKeys();
            }
        }

        fetchResponse();
    }

    function handleContentChange(id, message) {
        // Get message with id 7
        const index = messages.findIndex((message) => message.id === id);
        message = messages[index];
        // Send the message to the server
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiPostAuthAsync(token, "gpt/update-content", JSON.stringify([message]), "id=" + agent.id);
            if(messageResponse) {
                await fetchMessages();
            }
        }

        fetchResponse();
    }

    function drawAgents(namespace) {
        return namespace.map(agent =>
            <ListGroup.Item style={{cursor: "pointer"}}
                        tooltip={agent.description}
                        key={agent.id}
                        onClick={() => applyAgent(agent)}>
            {agent.name}
            <br/>
            <span style={{fontSize: 8}}>{agent.namespace}</span>
        </ListGroup.Item>);
    }

    function drawNamespace(namespace) {
        return <CollapsibleCard key={namespace} title={namespace[0].namespace}>
            <CollapsibleCardContent>
                {drawAgents(namespace)}
            </CollapsibleCardContent>
        </CollapsibleCard>
    }

    function renderAgents() {
        return <Card>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Agents</span>
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                {agents && Object.values(agents).map(namespace => {
                    if(agents.length == 1) {
                        return (drawAgents(namespace))
                    } else {
                        return (drawNamespace(namespace));
                    }
                })}
            </ListGroup>
        </Card>;
    }

    function getAgents() {
        return Object.values(agents).map(namespace => {
            return {
                label: namespace
            };
        });
    }

    function renderNamespaces() {
        return <Card className={"mt-5"}>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Namespaces</span>
                    <MdNewLabel className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                        setCreateNamespace(!createNamespace)
                    }} />
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                {namespaces && namespaces.map((agent, index) =>
                    <ListGroup.Item style={{cursor: "pointer"}}
                                    key={index}>
                        {agent.namespace}
                    </ListGroup.Item>)}
            </ListGroup>
        </Card>;
    }
    const transformToStars = (text) => {
        return text.replace(/[A-Za-z0-9]/g, '*');
    };

    function prepPost() {
        function escapeJsonForShell(jsonData) {
            return JSON.stringify(jsonData)
                .replace(/\\/g, '\\\\')  // Escape backslashes
                .replace(/"/g, '\\"');   // Escape double quotes
        }

        return <AgentPromptForm onPostDataChanged={(data) => {
            setRawPostData(data);
            setPostData(escapeJsonForShell(data));
        }} />;
    }

    function renderApiKeys() {
        function copyKey(apiKey) {
            navigator.clipboard.writeText(apiKey);
            toast.success("API Key copied to clipboard");
        }

        function createCurlPost(apiKey, hide) {
            return `curl -H "Authorization: Bearer ${hide ? transformToStars(apiKey) : apiKey}" \\
                -H "Content-Type: application/json" \\
                -d "${postData}" \\
                "https://api.aiart.doubtech.com/gpt/agent"`;
        }

        function createPythonCurlPost(apiKey, hide) {
            const transformedKey = hide ? '***' : apiKey;
            return `import requests

headers = {
    'Authorization': 'Bearer ${transformedKey}',
    'Content-Type': 'application/json',
}

data = ${JSON.stringify(rawPostData)}

response = requests.post('https://api.aiart.doubtech.com/gpt/agent', headers=headers, json=data)
print(response.text)`;
        }

        function createPhpCurlPost(apiKey, hide) {
            const transformedKey = hide ? '***' : apiKey;
            return `<?php

$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, 'https://api.aiart.doubtech.com/gpt/agent');
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, "${postData}");
curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  'Authorization: Bearer ${transformedKey}',
  'Content-Type: application/json',
));

$response = curl_exec($ch);
if (curl_errno($ch)) {
    echo 'Error:' . curl_error($ch);
}
curl_close($ch);

echo $response;
?>`;
        }


        function prepGet(apikey, hide) {
            return `curl -H "Authorization: Bearer ${hide ? transformToStars(apikey) : apikey}" "https://api.aiart.doubtech.com/gpt/agent?prompt=${encodeURIComponent(rawPostData?.prompt)}"`;
        }

        return <Card className={"mt-5"}>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>API Keys</span>
                    <MdNewLabel className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                        agent ? setCreateApiKey(!createApiKey) : setCreateApiKey(false);
                    }} />
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    {prepPost()}
                </ListGroup.Item>
                {apiKeys && apiKeys.map(key =>
                    <ListGroup.Item style={{cursor: "pointer"}}
                                    key={key.apikey}>
                        <div><div className={"create-card-flex-between-container"}>
                            {key.description}
                            <div className={"create-card-flex-between-items-grow"}/>
                            <MdLink className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {copyUrl(key.apikey)}} />
                            <MdDelete className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {deleteKey(key.apikey)}} />
                            <MdFileCopy className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {copyKey(key.apikey)}} />


                        </div>
                            <br/>
                            <CodePreview title={"GET"} language={"bash"} className={"mt-5"} clipboardCode={prepGet(key.apikey, false)}>
                                {prepGet(key.apikey, true)}
                            </CodePreview>
                            <CodePreview title={"POST"} language={"bash"} className={"mt-5"}
                                         clipboardCode={createCurlPost(key.apikey, false)}>
                                {createCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"python"} className={"mt-5"}
                                         clipboardCode={createPythonCurlPost(key.apikey, false)}>
                                {createPythonCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"php"} className={"mt-5"}
                                         clipboardCode={createPhpCurlPost(key.apikey, false)}>
                                {createPhpCurlPost(key.apikey, true)}
                            </CodePreview>
                        </div>
                    </ListGroup.Item>)}
            </ListGroup>
        </Card>
    }

    function rightColumn() {

        return <>




            {createNamespace && <Card>
                <Card.Header>
                    <div className={"create-card-flex-between-container"}>
                        <span>Create Namespace</span>
                    </div>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={handleCreateNamespace}>
                        <Form.Group controlId="content">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control value={newNamespaceName} onChange={(event) => setNewNamespaceName(event.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Card.Body>
            </Card>}



            {createApiKey && <Card>
                <Card.Header>
                    <div className={"create-card-flex-between-container"}>
                        <span>Create API Key</span>
                    </div>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={handleCreateApiKey}>
                        <Form.Group controlId="content">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control value={apiKeyDescription} onChange={(event) => setApiKeyDescription(event.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Card.Body>
            </Card>}
        </>;
    }

    async function updateModel(model) {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const response = await artApiFetchAuthAsync(token, "gpt/change-model",
            "agent=" + agent.name,
            "namespace=" + agent.namespace,
            "model=" + model);
        agent.model = model;
        fetchAgents();
        applyAgent(agent);
    }

    async function updateService(service) {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const response = await artApiFetchAuthAsync(token, "gpt/change-service",
            "agent=" + agent.name,
            "namespace=" + agent.namespace,
            "service=" + service);
        agent.service = service;
        fetchAgents();
        applyAgent(agent);
    }

    function content() {
        return (
        <>
            {agent && <div className='content'>





            </div>}
        </>);
    }

    function shareAgent() {
        // Get the first key from apiKeys
        if(apiKeys.length == 0) {
            toast.error("No API keys found");
            return;
        }
        const key = apiKeys[0];
        const url = window.location.origin + "/gpt/chat?key=" + key.apikey;
        navigator.clipboard.writeText(url);
        toast.success("Agent URL copied to clipboard");
    }

    function pasteAgentContent() {
        navigator.clipboard.readText().then(
            clipText => {
                try {
                    const clipMessages = JSON.parse(clipText);
                    if (clipMessages && clipMessages.length > 0) {
                        addContentSet(clipMessages);
                    }
                } catch (e) {
                    toast.error("Invalid clipboard content");
                }
            }
        )
    }

    function copyAgentContent() {
        // Copy messages to clipboard
        navigator.clipboard.writeText(JSON.stringify(messages));
    }

    function renderGraph() {
        return <LiteGraphComponent width={windowWidth - 200} height={windowHeight - 400} />
    }

    function renderContent() {
        return <>
            {messages && <div>
                <MessageList
                    messages={messages}
                    onDragEnd={onDragEnd}
                    onContentChange={handleContentChange}
                    onDeleteMessage={handleDeleteMessage}
                />
                {userInfo && userInfo.email == agent.owner && <div className={"create-card-flex-between-container"}>
                    <div className={"create-card-flex-between-items-grow"}/>{/* Spacer */}
                    <MdAddBox className={"mdicon mt-2"} style={{cursor: "pointer"}} onClick={() => {setShowAddContent(true)} } />
                </div>}
            </div>}
        </>;
    }

    function renderChat() {
        return <><AgentChatWindow
            agent={agent}  scroll={true}
            height={tabHeight - 100}
            showBackground={false}
        /></>;
    }

    function renderProperties() {
        return (
            <>
                <h2>
                    <span>{agent.name.toUpperCase()}</span>
                    <br/>
                    <span style={{fontSize: 16, color: "#888888"}}>{agent.namespace.toUpperCase()}</span>
                </h2>
                {agent.description && <div>
                    <span>{agent.description}</span>
                </div>}


                <div>
                    <strong>Service:</strong>
                    <DropdownButton id="dropdown-item-button" title={agent.service ?? "Select Service"}>
                    {services && services.map(service =>
                        <Dropdown.Item key={service} as="button"
                                       onClick={() => updateService(service)}>{service}</Dropdown.Item>
                    )}
                </DropdownButton>
                </div>

                <div>
                    <strong>Model:</strong>
                    <DropdownButton id="dropdown-item-button" title={agent.model ?? "Select Model"}>
                    {models && models.map(model =>
                        <Dropdown.Item key={model} as="button"
                                       onClick={() => updateModel(model)}>{model}</Dropdown.Item>
                    )}
                </DropdownButton>
                </div>

                {renderApiKeys()}
            </>
        )
    }

    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175", height: tabHeight}}>
                <div style={{backgroundColor: "#282828", padding: 16, border: "1px white", height: windowHeight - 400}}>
                    {content}
                </div>
            </Tab>
        );
    }

    const leftMenu = [
        {
            icon: MdApps, label: "Agents", dropdown: namespaceList.map((namespace) => {
            return {
                label: namespace,
                link: "/agent/" + namespace,
                submenu: agents[namespace].map((agent) => {
                    return {
                        label: agent.name,
                        onClick: () => applyAgent(agent)
                    }
                })
            }
        })},
        {icon: MdCopyAll, label: "", onClick: copyAgentContent, tooltip: "Copy Agent Content"},
        {icon: MdContentPaste, label: "", onClick: pasteAgentContent, tooltip: "Paste Agent Content"},
        {icon: MdNewLabel, label: "", onClick: pasteAgentContent, tooltip: "Paste Agent Content"},
        {icon: MdShare, label: "", onClick: shareAgent, tooltip: "Share Agent"}
    ];

    if (activeTab == "content") {
        leftMenu.push({
            icon: MdAdd,
            tooltip: "Add a new message to the base agent message history",
            label: "Add Message", onClick: () => {setShowAddContent(true)}});
    }

    return (
    <PageHeader image={headerImage} title={agent ? agent.name : "GPT Agent Design"}
                description={"Design agents to handle gpt prompts in specific ways"}
                breadcrumb={[
                    ["Home", "/"],
                    ["GPT", "/gptinfo"],
                    ["Agents", "/gpt/agent"]
                ]}
                menuleft={leftMenu}
                menuright={[
                    {icon: MdAdd, label: "Add Agent", onClick: () => setShowAddAgent(true)}
                ]}
                minimum={true}
                microfooter={true}
                stickyfooter={true}
    >
        {agent ? <Tabs activeKey={activeTab} defaultActiveKey="variables" id="uncontrolled-tab-example"
              onSelect={key => setActiveTab(key)}>
                {renderTab("chat", "Chat", renderChat())}
                {renderTab("content", "Content", renderContent())}
                {renderTab("graph", "Graph", renderGraph())}
                {renderTab("properties", "Properties", renderProperties())}
        </Tabs> :
        <div className='row'>
            Create or select an agent to get started.
        </div>
        }

        <div className='row'>
            <div className='col-md-8 flex-fill'>
                {content()}
            </div>
            <div className='col-md-4'>
                <div className='right'>
                    {rightColumn()}
                </div>
            </div>
        </div>

        {/*<FloatingForm label={"Test Agent: " + agent?.name} height={windowHeight / 2.0} outputValue={responseValue} onFormSubmit={(value) => {
            submitPrompt(value);
        }} isProcessing={isGenerating} processingLabel={"Generating..."} >
            <FloatingFormHeader>
                <label>
                    <input
                        type="checkbox"
                        checked={persistConversation}
                        onChange={e => setPersistConversation(e.target.checked)}
                    />
                    Persist Conversation
                </label></FloatingFormHeader>
        </FloatingForm>*/}

        <ModalDialog show={showAddContent} title={"Add Content"} acceptLabel={"Add"} onAccept={addContent} onClose={() => setShowAddContent(false)}>
            {agent && userInfo && userInfo.email == agent.owner &&
                <div>
                    <Form>
                        <Form.Group controlId="role">
                            <Form.Label>Role:</Form.Label>
                            <Form.Control as="select" value={messageRole} onChange={event => setRole(event.target.value)}>
                                <option value="user">User</option>
                                <option value="system">System</option>
                                <option value="assistant">Assistant</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="content">
                            <Form.Label>Content:</Form.Label>
                            <Form.Control as="textarea" rows={3} value={messageContent} onChange={(event) => setContent(event.target.value)} />
                        </Form.Group>
                    </Form>
                </div>
            }
        </ModalDialog>

        <ModalDialog show={showAddAgent} title={"Add Agent"} acceptLabel={"Create"} onAccept={handleCreateAgent} onClose={() => setShowAddAgent(false)}>
            <Form.Group controlId="content">
                <Form.Label>Name</Form.Label>
                <Form.Control value={newAgentName} onChange={(event) => setNewAgentName(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Label>Namespace</Form.Label>
                <Form.Control value={newAgentNamespace} onChange={(event) => setNewAgentNamespace(event.target.value)} />
                <DropdownButton id="dropdown-item-button" title={newAgentNamespace ?? namespaces[0]}>
                    {namespaces && namespaces.map(namespace =>
                        <Dropdown.Item key={namespace} as="button" onClick={() => setNewAgentNamespace(namespace.namespace)}>{namespace.namespace}</Dropdown.Item>
                    )}
                </DropdownButton>
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" value={newAgentDescription} onChange={(event) => setNewAgentDescription(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Check label={"Private"} type="checkbox" checked={newAgentIsPrivate} onChange={(event) => setNewAgentIsPrivate(event.target.checked)} />
            </Form.Group>
        </ModalDialog>
    </PageHeader>);
}

export default AgentDesign;