// LoadingContext.js
import React, {createContext, useContext, useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {startSession} from "../../hooks/artapi";
import {useError} from "./error";

const LoadingContext = createContext({
    loading: false,
    setLoading: null,
    setLoadingImage: null,
});

export function LoadingProvider({ children }) {
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(null);
    const value = { loading, setLoading, setLoadingImage };
    const [blockInput, setBlockInput] = useState(false);
    const [authComplete, setAuthComplete] = useState(false);
    const {setFatalError} = useError();

    function isLoading() {
        return loading && !authComplete;
    }

    useEffect(() => {
        async function completeAuth() {
            try {
                await startSession();
                setAuthComplete(true);
            } catch(e) {
                console.error("Failed to start session!", e);
                setFatalError("Unable to connect.");
            }
        }

        if(isAuthenticated && user) {
            completeAuth();
        } else if(!isAuthenticated) {
            completeAuth();
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        setBlockInput(loading);
        if(loading) {
            setTimeout(() => {
                setBlockInput(false);
            }, 1500);
        }
    }, [loading]);

    return (
        <LoadingContext.Provider value={value}>
            {children}
            {isLoading() && blockInput && <div style={{position: "fixed", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0)", zIndex: 1000, display: loading ? "block" : "none"}}>
                &nbsp;
            </div>}
            {isLoading() && !blockInput && <div style={{position: "fixed", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.81)", zIndex: 1000, display: loading ? "block" : "none"}}>
                <Spinner animation={"border"} role={"status"} style={{position: "fixed", top: "50%", left: "50%", zIndex: 1000, display: loading ? "block" : "none"}}/>
            </div>}
        </LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}