import React from 'react';
import Avatar from '@mui/material/Avatar';
import {Button, Card} from 'react-bootstrap';
import { JsonEditor } from "jsoneditor-react18";
import {MdContentCopy} from "react-icons/md";
import {toast} from "react-toastify";
import Message from "./message";

const isJson = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
};

const ChatBubble = ({
                        message: { content, sentTime, sender, direction, error },
                        user
                    }) => {

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        width: "100%",
        justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end'
    };

    const innerContainerStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        width: "100%",
        flexDirection: direction === 'incoming' ? 'row' : 'row-reverse'
    };

    const cardStyle = {
        borderRadius: '20px',
        width: isJson(content) ? '75%' : 'fit-content'
    };

    const senderInfoStyle = {
        fontSize: '0.8em',
        marginTop: '15px',
        marginBottom: "-10px",
        textAlign: direction === 'incoming' ? 'left' : 'right'
    };

    function formatSentTime(sentTime) {
        const currentDate = new Date();
        const sentDate = new Date(sentTime);

        const differenceInMilliseconds = currentDate - sentDate;
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

        if (differenceInMinutes < 60) {
            return `${differenceInMinutes} minutes ago`;
        } else {
            return sentDate.toLocaleString();
        }
    }

    const handleCopyToClipboard = () => {
        const textToCopy = isJson(content) ? JSON.stringify(JSON.parse(content), null, 4) : content;
        navigator.clipboard.writeText(textToCopy);
        toast.success("Copied to clipboard!");
    };

    return (
        <div style={containerStyle}>
            <div style={innerContainerStyle}>
                <Avatar src={user.avatar} alt={sender} style={{ marginRight: '10px' }} />
                    <Card style={cardStyle}>
                        <Card.Body>
                            <Message content={content} />
                            {error && <div style={{color: "red"}}>{error}</div>}
                            {direction === 'incoming' ?
                                <div style={senderInfoStyle} className={"create-card-flex-between-container"}>
                                    {sender} - {formatSentTime(sentTime)}
                                    <span className={"create-card-flex-between-items-grow"}/>
                                    {/* Copy to clipboard button */}
                                    <MdContentCopy
                                        className={"mdicon"}
                                        variant="outline-secondary"
                                        onClick={handleCopyToClipboard}
                                    />
                                </div> : <div style={senderInfoStyle} className={"create-card-flex-between-container"}>
                                    {/* Copy to clipboard button */}
                                    <MdContentCopy
                                        className={"mdicon"}
                                        variant="outline-secondary"
                                        onClick={handleCopyToClipboard}
                                    />
                                    <span className={"create-card-flex-between-items-grow"}/>
                                    {sender} - {formatSentTime(sentTime)}
                                </div>}
                        </Card.Body>
                    </Card>
            </div>
        </div>
    );
};

export default ChatBubble;
