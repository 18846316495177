import React, {useEffect, useRef} from "react";
import {ChatContainer, MainContainer, MessageInput, MessageList} from "@chatscope/chat-ui-kit-react";
import ChatBubble from "./chatbubble";
import useAgentChat from "../../pages/gpt/chathook";
import {useAuth0} from "@auth0/auth0-react";
import AutoScrollingDiv from "../../pages/gpt/AutoScrollingDiv";

function AgentChatWindow({agent, agentKey, height, scroll = false, showBackground=true}) {
    const { setAgent, setAgentKey, submitPrompt, messages, messageCount } = useAgentChat({agent, agentKey});
    const { getAccessTokenSilently, user } = useAuth0();
    const scrollingDivRef = useRef(null);

    useEffect(() => { setAgent(agent); }, [agent]);
    useEffect(() => { setAgentKey(agentKey); }, [agentKey]);

    useEffect(() => {
        if(scrollingDivRef.current) {
            scrollingDivRef.current.scrollToBottom();
        }
    }, [messages, agent, agentKey, messageCount]);

    function drawChatHistory() {
        return <div style={{padding: "20px"}}>
            {messages && messages.map((message, index) =>
            <ChatBubble
                key={index}
                message={message}
                user={{avatar: message.direction === "incoming" ? "" : user?.picture}}
            />)}
        </div>;
    }

    return (
        <MainContainer style={showBackground ? {
                backgroundColor: "#252424",
                borderRadius: "20px"
        } : {}}>
            {scroll ? <AutoScrollingDiv autoscroll={false} height={height} ref={scrollingDivRef}>{drawChatHistory()}</AutoScrollingDiv>
                : <div>{drawChatHistory()}</div>
            }
            <ChatContainer className={"mt-3"}>
                <MessageList>

                </MessageList>
                <MessageInput
                    placeholder="Type message here"
                    attachButton={false}
                    onSend={(html, text, nodes) => submitPrompt(text)} />
            </ChatContainer>
        </MainContainer>
    );
}

export default AgentChatWindow;